import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TemplateHeader from '../TemplateHeader';
import ArrowButton from '../ArrowButton';

import carletonImage from '../../assets/homePage/landingPage/carletonCampus_lineDrawing.png';

const Styles = styled.div`
    .publication-image {
        max-width: 100%;
        height: auto;
    }

    .grey-button {
        background-color: #595A5A;
        color: #FFFFFF;
        padding: 0.5em;
        margin-right: 1em;
        font-weight: 100;
        font-size: 0.8em;
    }

    .author-button {
        &:hover {
            background-color: #262626;
            color: #99B6AE;
            cursor: pointer;
        }
    }

    .publication-image {
        border-radius: 0;
        border: 0;
    }

    .h4 {
        font-weight: 300;
        letter-spacing: 0.2rem;
    }
`

class PublicationsPage_Info extends React.Component {
    constructor(props) {
        super (props)

        this.state = {
            image: ""
        }
    }

    handleAuthorClick(name) {
        console.log(name);
        this.props.history.push(`/researchers/${name}`);
    }

    componentDidMount() {
        if(!this.props.publication.image) {
            this.setState({
                image: require("../../assets/publications/publication_placeholder.png")
            })
        } else {
            this.setState({
                image: require(`../../assets/publications/${this.props.publication.image}`)
            });
        }
    }

    render() {
        return (
            <Styles>
                <div className="background-color vh-100">
                    <div className="template-header mb-5">
                        <TemplateHeader
                            backgroundImage={carletonImage}
                            titleWhite="URBAN FUTURES"
                            titleGreen="PUBLICATIONS"
                            titleDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget ante eu quam blandit sodales. Curabitur iaculis mauris sem, at semper mauris facilisis id."
                            className="template-header"
                        />
                    </div>
                    <Row className="background-color m-0">
                        <Col xs={{span:11, offset:1}}>
                            <Row>
                                <Col xs={{span:5}} sm={{span:3}} md={{span:2}} className="mb-3 mr-5">
                                    <Row>
                                        <Image 
                                            src={this.state.image}
                                            thumbnail
                                            className="publication-image p-0 background-color"
                                            alt="Publication Thumbnail"
                                        />
                                    </Row>
                                </Col>
                                <Col xs={{span:11}} md={{span:9}}>
                                    <Row>
                                        <h4 style={{color:'#F2F2F2', fontWeight:500, fontSize:'1.5em'}}>{this.props.publication.title}</h4>
                                    </Row>
                                    <Row>
                                        <h4>PUBLISHER: {this.props.publication.publisher}</h4>
                                    </Row>
                                    <Row>
                                        <h4>RELEASE DATE: {this.props.publication.release}</h4>
                                    </Row>
                                    <Row className="d-flex align-items-center">
                                        <h4 className="mr-3">AUTHORS:</h4>
                                        {this.props.publication.authors.map((author, i) => {
                                            return (
                                                <Link to={`/researchers/${author.name}`} style={{textDecoration: 'none'}}>
                                                    <h4 className="grey-button author-button" key={i} /*onClick={() => this.handleAuthorClick(author.name)}*/>{author.name}</h4>
                                                </Link>
                                            );
                                        })}
                                    </Row>
                                    <Row>
                                        <h4 className="title-line">ISBN: {this.props.publication.isbn}</h4>
                                    </Row>
                                    
                                    <Row>
                                        <p className="mt-3">{this.props.publication.description}</p>
                                    </Row>
                                    <Row className="d-flex align-items-center">
                                        <h4 className="mr-3">SUBJECTS:</h4>
                                        {this.props.publication.subjects.map((subject, i) => {
                                            return (
                                                <h4 className="grey-button" key={i}>{subject}</h4>
                                            );
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="background-color pb-5 m-0">
                        <Col xs={{span:11, offset:1}} className="p-0">
                            <Link to="/publications" style={{textDecoration: 'none'}}>
                                <ArrowButton direction="left" buttonText="BACK TO PUBLICATIONS" />
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Styles>
        );
    }
}

export default PublicationsPage_Info;