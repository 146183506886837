import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import SubtitleButtons from './DesignCharrettes_Buttons';
import CharretteText from './DesignCharrettes_Text';
import CharretteLogos from './DesignCharrettes_Logos';
import CharretteCarousel from './DesignCharrettes_Carousel';
import ArrowButton from '../../ArrowButton';

const Styles = styled.div`
    .publications-container {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    @media all and (max-width: 992px) {
        .display-bottom {
            position: inital;
            margin-top: 3em;
            margin-bottom: 3em;
            margin-left: 0;
            margin-right: 0;
        }

        .info-visibility {
            display: none;
        }
    }

    @media all and (min-width: 992px) {
        .display-bottom {
            bottom: 0;
            position: absolute;
            margin: 0;
        }

        .info-visibility {
            display: block;
        }
    }
`

class HomePage_DesignCharrettes extends React.Component {
    TRANSPORT_INFO_LINK = "/designcharrette-transport";
    CLIMATE_INFO_LINK = "/designcharrette-climate";
    ECONOMY_INFO_LINK = "/designcharrette-economy";
    
    state = {
        display: "transport",
        infoLink: this.TRANSPORT_INFO_LINK
    };

    handleSubtitleClick = buttonId => {
        if(buttonId === "transport") {
            this.setState({
                display: "transport",
                infoLink: this.TRANSPORT_INFO_LINK
            });
        }else if (buttonId === "climate") {
            this.setState({
                display: "climate",
                infoLink: this.CLIMATE_INFO_LINK
            });
        }else if (buttonId === "economy") {
            this.setState({
                display: "economy",
                infoLink: this.ECONOMY_INFO_LINK
            });
        }
    }

    render() {
        return (
            <Styles>
                <div className="d-flex flex-column publications-container background-color">
                    <div className="flex-column p-0 m-0">
                        <Col xs={{span:5, offset:1}} className="">
                            <Row className="p-0">
                                <h1>DESIGN</h1>
                            </Row>      
                            <Row className="p-0">
                                <h2 className="title-line">CHARRETTES</h2>
                            </Row>
                        </Col>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                        <Col xs={{span:12}} className="h-100 p-0">
                            <Row className="p-0 h-100 m-0">
                                <Col xs={{span:10, offset:1}} lg={{span:3, offset:1}} className="p-0 mr-0">
                                    <div className="mb-3">
                                        <SubtitleButtons handleClick={this.handleSubtitleClick} />
                                    </div>
                                    <div className="info-visibility display-bottom">
                                        <Row className="mb-3 mt-0 ml-0 mr-0">
                                            <CharretteLogos charrette={this.state.display} />
                                        </Row>
                                        <Row className="m-0">
                                            <CharretteText charrette={this.state.display} className="mt-3" />
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={{span:10, offset:1}} lg={{span:7, offset:1}} className="d-flex align-items-center justify-content-center h-100 w-100 p-0">
                                    <div className="w-100">
                                        <CharretteCarousel charrette={this.state.display} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    <Link to={this.state.infoLink} style={{textDecoration: 'none'}}>
                        <ArrowButton buttonText="VIEW MORE INFORMATION" direction="right" />
                    </Link>
                </div>
            </Styles>
        );
    }
}

export default HomePage_DesignCharrettes;