import React from 'react';
import styled from 'styled-components';
import { Jumbotron as Jumbo, Col, Row } from 'react-bootstrap';
import { Link } from 'react-scroll';
import phone_icon from '../../assets/homePage/contactPage/phone_icon.png';
import email_icon from '../../assets/homePage/contactPage/email_icon.png';
import website_icon from '../../assets/homePage/contactPage/website_icon.png';
import address_icon from '../../assets/homePage/contactPage/address_icon.png';

const Styles = styled.div`
    .footer-background {
        background-color:  #262626;
    }

    .footer-title {
        color: #F2F2F2;
    }
    
    .title-line {
        padding-bottom: 5px;
        margin-bottom: 1em;
    }

    p {
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

    .p-hover {
        &:hover {
            color: #99B6AE;
        }
    }

    .contact-text-margin {
        margin-top: 5px;
        margin-left: 1em;
        margin-bottom: 0;
    }

    .clickable {
        cursor: pointer;
    }

    @media all and (max-width: 1020px) {
        .contact-text-margin {
            margin-top: 7px;
        }
    }

    @media all and (max-width: 768px) {
        .responsive-column {
            margin-top: 3em;
        }
    }
`

class HomePage_Footer extends React.Component {
    handleClick = (item) => {
        if(item === "address") {
            window.open('https://www.google.com/maps/place/VSIM+(Visualization,+Simulation+and+Modeling)/@45.3805357,-75.7023887,17z/data=!4m12!1m6!3m5!1s0x4cce0676c4570021:0x6c968b17d1b20924!2sVSIM+(Visualization,+Simulation+and+Modeling)!8m2!3d45.380532!4d-75.7002!3m4!1s0x4cce0676c4570021:0x6c968b17d1b20924!8m2!3d45.380532!4d-75.7002', "VSIM Map");
        }else if(item === "website") {
            window.open('http://cims.carleton.ca', "CIMS Lab Website")
        }
    }

    render() {
        return(
            <Styles>
                <Jumbo fluid className="footer-background d-flex m-0">
                    <Col xs={{span:10, offset:1}}>
                        <Row>
                            <Col xs={{span:10, offset:1}} md={{span:4, offset:0}}>
                                <Row>
                                   <h4 className="footer-title title-line">PROJECT</h4> 
                                </Row>
                                <Row>
                                    <Link
                                        activeClass="active"
                                        to="project-overview"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                    >
                                        <p className="clickable p-hover">About</p>
                                    </Link>
                                </Row>
                                <Row>
                                    <Link
                                        activeClass="active"
                                        to="design-charrettes"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                    >
                                        <p className="clickable p-hover">Design Charrettes</p> 
                                    </Link>
                                </Row>
                                <Row>
                                    <Link
                                        activeClass="active"
                                        to="researchers"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                    >
                                        <p className="clickable p-hover">Researchers</p> 
                                    </Link>
                                </Row>
                                <Row>
                                    <Link
                                        activeClass="active"
                                        to="contact-page"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                    >
                                        <p className="mb-0 clickable p-hover">Contact Information</p> 
                                    </Link>
                                </Row>
                            </Col>
                            {/*<Col xs={{span:10, offset:1}} md={{span: 2, offset:1}} className="responsive-column">
                                <Row>
                                   <h4 className="footer-title title-line">FOLLOW US</h4> 
                                </Row>
                                <Row>
                                   <p className="clickable p-hover" onClick={() => this.handleSocialMediaClick("facebook")}>Facebook</p> 
                                </Row>
                                <Row>
                                   <p className="clickable p-hover" onClick={() => this.handleSocialMediaClick("instagram")}>Instagram</p> 
                                </Row>
                                <Row>
                                   <p className="clickable p-hover" onClick={() => this.handleSocialMediaClick("linkedin")}>LinkedIn</p> 
                                </Row>
                                <Row>
                                   <p className="mb-0 clickable p-hover" onClick={() => this.handleSocialMediaClick("twitter")}>Twitter</p> 
                                </Row>
                            </Col>*/}
                            <Col xs={{span:10, offset:1}} md={{span: 5, offset:1}} className="responsive-column">
                                <Row className="mb-2">
                                    <img
                                        src={phone_icon}
                                        width="30px"
                                        height="30px"
                                        alt="Phone Icon"
                                    />
                                    <p className="contact-text-margin">(613)-520-2600</p>
                                </Row>
                                <Row className="mb-2">
                                    <img
                                        src={email_icon}
                                        width="30px"
                                        height="30px"
                                        alt="Phone Icon"
                                    />
                                    <p className="contact-text-margin">info@cims.carleton.ca</p>
                                </Row>
                                <Row className="mb-2">
                                    <img
                                        src={website_icon}
                                        width="30px"
                                        height="30px"
                                        alt="Website Icon"
                                        className="clickable"
                                        onClick={() => this.handleClick("website")}
                                    />
                                    <p className="contact-text-margin clickable p-hover" onClick={() => this.handleClick("website")}>http://cims.carleton.ca</p>
                                </Row>
                                <Row>
                                    <img
                                        src={address_icon}
                                        className="clickable"
                                        width="30px"
                                        height="30px"
                                        alt="Phone Icon"
                                        onClick={() => this.handleClick("address")}
                                    />
                                    <p className="contact-text-margin p-hover clickable" onClick={() => this.handleClick("address")}>
                                        Carleton Immersive Media Studio<br />
                                        Carleton University<br />
                                        Virtual & Simulation Building,4<sup>th</sup> Floor<br />
                                        1125 Colonel By Drive<br />
                                        Ottawa, ON, K1S 5B6
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Jumbo>
            </Styles>
        );
    }
}

export default HomePage_Footer; 