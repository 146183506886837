import React from 'react';

import transportLogo from '../../../assets/homePage/designCharrettes/logos/urbanTransport_interIntra_white_logo.svg';
import climateLogo from '../../../assets/homePage/designCharrettes/logos/urbanClimate_interIntra_white_logo.svg';
import economyLogo from '../../../assets/homePage/designCharrettes/logos/urbanEconomy_interIntra_white_logo.svg';

class DesignCharrettes_Logos extends React.Component {
    TRANSPORT_ALT = "Urban Transport Logo";
    CLIMATE_ALT = "Urban Climate Logo";
    ECONOMY_ALT = "Urban Economy Logo";
    
    constructor(props) {
        super(props)

        this.state = {
            charrette: this.props.charrette,
            logoUrl: transportLogo,
            logoAlt: this.TRANSPORT_ALT
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(this.props.charrette !== prevProps.charrette) {
            this.setState({
                charrette: this.props.charrette
            });
        }

        if(this.state.charrette !== prevState.charrette) {
            this.updateLogo();
        }
    }

    updateLogo() {
        if(this.state.charrette === "transport") {
            this.setState({
                logoUrl: transportLogo,
                logoAlt: this.TRANSPORT_ALT
            });
        }else if(this.state.charrette === "climate") {
            this.setState({
                logoUrl: climateLogo,
                logoAlt: this.CLIMATE_ALT
            });
        }else if(this.state.charrette === "economy") {
            this.setState({
                logoUrl: economyLogo,
                logoAlt: this.ECONOMY_ALT
            });
        }
    }

    render() {
        return(
            <React.Fragment>
                <img
                    src={this.state.logoUrl}
                    width="140px"
                    height="auto"
                    alt={this.state.logoAlt}
                />
            </React.Fragment>
        );
    }
}

export default DesignCharrettes_Logos;