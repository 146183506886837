import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import styled from 'styled-components';
//import ShowMoreText from 'react-show-more-text';

const Styles = styled.div`
    .info {
        background-color: #262626;
    }

    .heading-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

class ResearcherCard extends React.Component {
    containerArea;

    constructor(props) {
        super(props)
        
        this.state = {
            image: "",
            paragraphLineHeight: 0,
            renderText: null,
            containerDimensions: [],
            titlesDimensions: [],
            numLines: 0
        }

        this.containerArea = 0;
    }

    componentDidMount() {
        if(!this.props.image) {
            this.setState({
                image: require("../../assets/researchers/researcher_placeholder.png")
            })
        } else {
            this.setState({
                image: require(`../../assets/researchers/${this.props.image}`)
            });
        }
    }

    renderSpecialisation() {
        if(this.props.specialization) {
            return `- ${this.props.specialization}`;
        }
    }

    render() {
        return(
            <Styles>
                <Row>
                    <Col xs={{span:10, offset:1}} md={{span:7, offset:0}} lg={{span:5, offset:0}} xl={{span:4, offset:1}} className="p-0">
                        <Image
                            src={this.state.image}
                            width="100%"
                            height="auto"
                        />
                    </Col>
                    <Col xs={{span:10, offset:1}} md={{span:5, offset:0}} lg={{span:7, offset:0}} xl={{span:6, offset:0}} className="p-0">
                        <div className="h-100 p-4 info d-flex align-items-center">
                            <Col>
                                <Row>
                                    <h4 className="heading-text title-line pb-1" style={{color:'#F2F2F2', textTransform:'uppercase', fontSize: "1.3em"}}>{this.props.name}</h4>
                                </Row>
                                <Row>
                                    <h4 className="heading-text" style={{fontWeight:300, letterSpacing:"0.2rem", color:'#F2F2F2', textTransform:'uppercase'}}>{this.props.institution}</h4>
                                </Row>
                                <Row>
                                    <h4 className="heading-text" style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>{this.props.faculty}</h4>
                                </Row>
                                <Row>
                                    <h4 className="heading-text m-0" style={{ fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>{this.props.department} {this.renderSpecialisation()}</h4>
                                </Row>
                            </Col>
                            {/*<h4 className="heading-text" style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>Biography:</h4>
                            <div id="text">
                                <ShowMoreText lines={2} more='' less=''>
                                    <p>
                                        {this.props.bio}
                                    </p>
                                </ShowMoreText>
                            */}
                        </div>
                    </Col>
                </Row>
            </Styles>
        );
    }
}

export default ResearcherCard;