import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import styled from 'styled-components';
import ShowMoreText from 'react-show-more-text';

const Styles = styled.div`
    .info {
        background-color: #262626;
    }

    .heading-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

class PublicationCard extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            image: ""
        }
    }

    componentDidMount() {
        if(!this.props.image) {
            this.setState({
                image: require("../../assets/publications/publication_placeholder.png")
            })
        } else {
            this.setState({
                image: require(`../../assets/publications/thumbnails/${this.props.image}`)
            });
        }
    }

    render() {
        return(
            <Styles>
                <Row>
                    <Col xs={{span:10, offset:1}} md={{span:7, offset:0}} lg={{span:5, offset:0}} xl={{span:4, offset:1}} className="p-0">
                        <Image
                            src={this.state.image}
                            width="100%"
                            height="auto"
                            alt={`Thumbnail ${this.props.title}`}
                        />
                    </Col>
                    <Col xs={{span:10, offset:1}} md={{span:5, offset:0}} lg={{span:7, offset:0}} xl={{span:6, offset:0}} className="p-0">
                        <div className="h-100 p-4 info">
                            <h4 className="heading-text" style={{color:'#F2F2F2', textTransform:'uppercase', fontSize: "1.3em"}}>{this.props.title}</h4>
                            <h4 className="heading-text" style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>{this.props.publisher}</h4>
                            <h4 className="heading-text" style={{color:'#F2F2F2', fontWeight:300, letterSpacing:"0.2rem"}}>{this.props.release}</h4>
                            <h4 className="heading-text title-line" style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>{this.props.isbn}</h4>
                            <h4 className="heading-text" style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>DESCRIPTION:</h4>
                            <ShowMoreText lines={1} more='' less=''>
                                <p>{this.props.description}</p>
                            </ShowMoreText>
                        </div>
                    </Col>
                </Row>
            </Styles>
        );
    }
}

export default PublicationCard;