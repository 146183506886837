import React from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron as Jumbo, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import TemplateHeader from '../TemplateHeader';
import MoreInfoButtons from './DesignCharrettePage_Buttons';
import ArrowButton from '../ArrowButton';
import CharretteCarousel from '../homePage/designCharrettes/DesignCharrettes_Carousel';

import HeaderImage from '../../assets/homePage/designCharrettes/transport1.png';

const Styles = styled.div`
    .purpleText {
        color: #8B7295;
    }
`

class TransportCharrettePage extends React.Component {
    render() {
        return(
            <Styles>
                <div className="background-color vh-100">
                    <TemplateHeader 
                        titleWhite="TRANSPORTATION" 
                        titleGreen="DESIGN CHARRETTE" 
                        backgroundImage={HeaderImage} 
                        logo="transport" 
                        theme="purple"
                    />
                    <MoreInfoButtons page="transport"/>
                    <Jumbo fluid className="background-color mb-0">
                        <Col xs={{span:10, offset:1}}>
                            <Row>
                                <h4 className="purpleText">URBAN FUTURES DESIGN WORKSHOPS</h4>
                                <p className="p1 mb-5">
                                    <i>Urban Futures: intra/inter</i> sets the stage for the development of a unique model for inclusive and multidisciplinary collaboration. Our objective is to initiate a multidisciplinary research cluster that is capable of generating diverse and compelling knowledge about the future of digital technologies for urbanism in Canada and abroad.  We will achieve this through a series of intensive design charrettes — where participants will discuss, debate and develop ideas in reaction to a themed ‘what-if’ scenario.
                                </p>
                            </Row>
                            <Row>
                                <h4 className="purpleText" style={{textTransform:'uppercase'}}>How do you organize an engaging multidisciplinary workshop that asks participants to challenge the vast idea of the smart city?</h4>
                                <p className="p1 mb-5">
                                    The Urban Futures research group brings together members researchers from industry, government, academia, and not-for-profit organizations to question and challenge the social and operational dimensions of “smart city” technologies. Our series of workshops — modelled after architectural design charrettes — invite participants to discuss, debate, react, and develop multidisciplinary research questions based on ‘what-if’ scenarios. We advocate for knowing through making and understand problem-solving as an iterative, collaborative, critical and inclusive design exercise.
                                </p>
                            </Row>
                            <Row>
                                <h4 className="purpleText">TRANSPORTATION WORKSHOP</h4>
                            </Row>
                            <Row className="mt-3 d-flex justify-content-center mb-3">
                                <div className="w-100">
                                    <CharretteCarousel charrette="transport" className="mt-3" />
                                </div>
                            </Row>
                            <Row>
                                <p className="p1">What challenges arise from implementing autonomous vehicles at the scale of a campus, city, and region?</p>
                                <p>
                                    Urban Futures researchers addressed significant research questions such as this in our Transportation Workshop. Provoked by an animated ‘what-if’ scenario, participants discussed how to enhance the benefits, mitigate the drawbacks, and address the unknowns arising from the scenario. Topics including data security, accessibility, equity and inclusion, and urban mobility were subjected to a multidisciplinary discussion resulting in several research ideas. 
                                </p>
                            </Row>
                            <Row>
                                <Link to="/" style={{textDecoration: 'none'}}>
                                    <ArrowButton buttonText="BACK" direction="left" />
                                </Link>
                            </Row>
                        </Col>
                    </Jumbo>
                </div>
            </Styles>
        );
    }
}

export default TransportCharrettePage;