import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import ArrowButton from '../../ArrowButton';
import ResearchersData from '../../researchersPage/ResearchersData.json';
import ResearcherCard from '../../researchersPage/ResearcherCard';

const Styles = styled.div`
    .publications-container {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .clickable {
        cursor: pointer;
    }
`

class HomePage_Researchers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            researchers: ResearchersData,
            elementHeight: 0
        }
    }

    handleCardClick(researcher) {
        this.props.history.push(`/researchers/${researcher.partnerName}`);
    }
    
    renderResearcherCards() {
        return this.state.researchers.map((researcher, i) => {
            return (
                <Carousel.Item key={i}>
                    <Row>
                        <Col xs={{span:8, offset:2}} >
                            <Link to={`/researchers/${researcher.partnerName}`} style={{textDecoration: 'none'}} className="clickable">
                                <ResearcherCard name={researcher.partnerName} institution={researcher.institution} faculty={researcher.faculty} department={researcher.department} specialization={researcher.specialization} image={researcher.image} />
                            </Link>
                        </Col>
                    </Row>
                </Carousel.Item>
            );
        });
    }
    
    render() {
        return(
            <Styles>
                <div className="vh-100 d-flex flex-column px-2 publications-container background-color">
                    <div className="flex-column">
                        <Col xs={{span:10, offset:1}}>
                            <Row>
                                <h1>PROJECT</h1>
                            </Row>      
                            <Row>
                                <h2 className="title-line">RESEARCHERS</h2>
                            </Row>
                        </Col>
                    </div>
                    <div className="d-flex flex-grow-1 overflow-auto align-items-center">
                        <Col xs={{span:12}}>
                            <Carousel indicators={false} interval="100000">
                                {this.renderResearcherCards()}
                            </Carousel>
                        </Col>
                    </div>
                    <div>
                        <Link to="/researchers" style={{textDecoration: 'none'}}>
                            <ArrowButton buttonText="VIEW ALL" direction="right" />
                        </Link>
                    </div>
                </div>
            </Styles>
        );
    }
}

export default HomePage_Researchers;