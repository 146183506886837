import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { HomePage } from './homePage/HomePage';
import { NoMatch } from './NoMatch';
import ProjectOverviewPage from './projectOverviewPage/ProjectOverviewPage';
import TransportCharrettePage from './designCharrettesPage/TransportCharrettePage'
import ClimateCharrettePage from './designCharrettesPage/ClimateCharrettePage';
import EconomyCharrettePage from './designCharrettesPage/EconomyCharrettePage';
import ResearchersPage from './researchersPage/ResearchersPage';
import PublicationsPage from './publicationsPage/PublicationsPage';
import './App.css'
//Add icons to library so they can be called at any point in the application (see ContactPage for an example)
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

class App extends React.Component {
    render() {
        return (
            <React.Fragment>
                    <Router history={this.props.history}>
                        <ScrollMemory/>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/project-overview" component={ProjectOverviewPage} />
                            <Route path="/designcharrette-transport" component={TransportCharrettePage} />
                            <Route path="/designcharrette-climate" component={ClimateCharrettePage} />
                            <Route path="/designcharrette-economy" component={EconomyCharrettePage} />
                            <Route path="/researchers/:researcher?" component={ResearchersPage} />
                            <Route path="/publications/:publication?" component={PublicationsPage} />
                            <Route component={NoMatch}/>
                        </Switch>
                    </Router>
            </React.Fragment>
        );
    }
}

export default App;