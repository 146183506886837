import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PublicationCard from './PublicationCard';
import TemplateHeader from '../TemplateHeader';
import ArrowButton from '../ArrowButton';

import carletonImage from '../../assets/homePage/landingPage/carletonCampus_lineDrawing.png';

const Styles = styled.div`
    .template-header {
        padding-bottom: 3rem;
    }
`

class PublicationsPage_Cards extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            publications: this.props.publications
        }
    }

    render() {
        return(
            <Styles>
                <div className="vh-100 background-color">
                    <div className="template-header">
                        <TemplateHeader
                            backgroundImage={carletonImage}
                            titleWhite="URBAN FUTURES"
                            titleGreen="PUBLICATIONS"
                            titleDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget ante eu quam blandit sodales. Curabitur iaculis mauris sem, at semper mauris facilisis id."
                            className="template-header"
                        />
                    </div>
                    <Row className="background-color m-0">
                        <Col xs={{span:11}}>
                            <Row> 
                                {this.state.publications.map((publication, i) => {
                                    return (
                                        <Col 
                                            xs={{span:11, offset:1}} 
                                            sm={{span:5, offset:1}} 
                                            className="mb-5 clickable" 
                                            key={i} 
                                            onClick={() => { this.props.cardClick(publication); }}
                                        >
                                            <PublicationCard 
                                                key={i} 
                                                title={publication.title} 
                                                publisher={publication.publisher} 
                                                release={publication.release} 
                                                isbn={publication.isbn} 
                                                description={publication.description}
                                                image={publication.image}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="background-color pb-5 m-0">
                        <Col xs={{span:11, offset:1}} className="p-0">
                            <Link to="/" style={{textDecoration: 'none'}}>
                                <ArrowButton direction="left" buttonText="BACK TO HOME" />
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Styles>
        );  
    }
}

export default PublicationsPage_Cards;