import React from 'react';
import PublicationsInfo from './PublicationsPage_Info';
import PublicationsData from './PublicationsData.json';
import PublicationsCards from './PublicationsPage_Cards';

class PublicationsPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            publications: PublicationsData
        }
    }

    handleCardClick(publication) {
        this.props.history.push(`/publications/${publication.title}`);
    }
    
    removeSpaces(str) {
        return str.replace(" ", "").replace("%20", "");
    }

    render() {
        if(!this.props.match.params.publication) {
            return <PublicationsCards publications={this.state.publications} cardClick={publication => this.handleCardClick(publication)} />
        }

        const publication = this.state.publications.find(res => {
            return this.removeSpaces(res.title) === this.removeSpaces(this.props.match.params.publication);
        });

        return <PublicationsInfo publication={publication} />
    }
}

export default PublicationsPage;