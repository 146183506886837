import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TemplateHeader from '../TemplateHeader';
import ArrowButton from '../ArrowButton';

import carletonImage from '../../assets/homePage/landingPage/carletonCampus_lineDrawing.png';

const Styles = styled.div`
    .researcher-image {
        max-width: 100%;
        height: auto;
    }
`

class ResearchersPage_Bios extends React.Component {
    constructor(props) {
        super (props)

        this.state = {
            image: ""
        }
    }

    componentDidMount() {
        if(!this.props.researcher.image) {
            this.setState({
                image: require("../../assets/researchers/researcher_placeholder.png")
            })
        } else {
            this.setState({
                image: require(`../../assets/researchers/${this.props.researcher.image}`)
            });
        }
    }

    renderSpecialisation() {
        if(this.props.researcher.specialization) {
            return `- ${this.props.researcher.specialization}`;
        }
    }

    render() {
        return (
            <Styles>
                <div className="background-color vh-100">
                    <div className="template-header mb-5">
                        <TemplateHeader
                            backgroundImage={carletonImage}
                            titleWhite="MEET THE"
                            titleGreen="RESEARCHERS"
                            titleDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget ante eu quam blandit sodales. Curabitur iaculis mauris sem, at semper mauris facilisis id."
                            className="template-header"
                        />
                    </div>
                    <Row className="background-color m-0">
                        <Col xs={{span:11, offset:1}}>
                            <Row>
                                <Col xs={{span:5}} sm={{span:3}} md={{span:2}} className="mb-3 mr-5">
                                    <Row>
                                        <img
                                            src={this.state.image}
                                            alt="Researcher Profile"
                                            className="researcher-image"
                                        />
                                    </Row>
                                </Col>
                                <Col xs={{span:11}} md={{span:9}}>
                                    <Row>
                                        <h4 style={{color:'#F2F2F2', textTransform:'uppercase', fontSize: "1.3em"}}>{this.props.researcher.partnerName}</h4>
                                    </Row>
                                    <Row>
                                        <h4 style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>{this.props.researcher.institution}</h4>
                                    </Row>
                                    <Row>
                                        <h4 style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>{this.props.researcher.faculty}</h4>
                                    </Row>
                                    <Row>
                                        <h4 style={{color:'#F2F2F2', fontWeight:300, letterSpacing:"0.2rem", marginBottom: "1em"}} className="title-line">{this.props.researcher.department} {this.renderSpecialisation()}</h4>
                                    </Row>
                                    <Row>
                                        <h4 style={{fontWeight:300, letterSpacing:"0.2rem", textTransform:'uppercase'}}>RESEARCHER BIOGRAPHY:</h4>
                                    </Row>
                                    <Row>
                                        <p>{this.props.researcher.bios}</p>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="background-color pb-5 m-0">
                        <Col xs={{span:11, offset:1}} className="p-0">
                            <Link to="/researchers" style={{textDecoration: 'none'}}>
                                <ArrowButton direction="left" buttonText="BACK TO RESEARCHERS" />
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Styles>
        );
    }
}

export default ResearchersPage_Bios;