import React from 'react';
import { LandingPage } from './LandingPage';
import { ProjectOverview } from './ProjectOverview';
import Contact from './contactPage/HomePage_Contact';
import DesignCharrettes from './designCharrettes/HomePage_DesignCharrettes';
import Researchers from './researchers/HomePage_Researchers';
//import Publications from './publications/HomePage_Publications';
import Footer from './HomePage_Footer'
import { NavigationBar } from '../NavigationBar';

export const HomePage = () => {
    return (
        <React.Fragment>
            <NavigationBar />
            {/* Classnames used for react-scroll in NavigationBar.js */}
            <div className="landing-page">    
                <LandingPage />
            </div>
            <div className="project-overview">
                <ProjectOverview />
            </div>
            <div className="design-charrettes">
                <DesignCharrettes />
            </div>            
            <div className="researchers">
                <Researchers />
            </div>
            {/*<div className="publications">
                <Publications />
            </div>*/}
            <div className="contact-page">
                <Contact />
            </div>
            <Footer />
        </React.Fragment>
    );
}