import React from 'react';
import styled from 'styled-components';
import { HomeButton } from './HomeButton';
import { Jumbotron as Jumbo, Container, Row, Col } from 'react-bootstrap';

import urbanFutures_white_logo from '../assets/homePage/landingPage/urbanFutures_white_logo.svg';
import urbanTransport_white_logo from '../assets/homePage/designCharrettes/logos/urbanTransport_interIntra_white_logo.svg';
import urbanClimate_white_logo from '../assets/homePage/designCharrettes/logos/urbanClimate_interIntra_white_logo.svg';
import urbanEconomy_white_logo from '../assets/homePage/designCharrettes/logos/urbanEconomy_interIntra_white_logo.svg'

const Styles = styled.div`
    .jumbo {
        background-size: cover;
        z-index: -2;
        display: block;
        margin-bottom: 0px;
        height: 100%;
        border-radius: 0;
        background-color: rgba(0,0,0,0.5);
        background-blend-mode: darken;
    }

    .logo {
        margin-bottom: 5em;
        width: 12vw;
        height: auto;
    }

    @media all and (min-width: 1200px) { 
        .logo {
            width: 10vw;
        }
    }

    @media all and (max-width: 1020px) { 
        .logo {
            width: 15vw;
        }
    }

    @media all and (max-width: 992px) { 
        .logo {
            width: 20vw;
        }
    }

    @media all and (max-width: 576px) { 
        .logo {
            width: 30vw;
        }
    }
`

class TemplateHeader extends React.Component {
    GREEN_COLOR = "#99B6AE";
    PURPLE_COLOR = "#8B7295";
    YELLOW_COLOR = "#F8EB9F";
    RED_COLOR = "#EF907B";

    constructor(props) {
        super(props);

        this.state = ({
            logo: urbanFutures_white_logo,
            theme: this.GREEN_COLOR
        })
    }

    componentDidMount() {
        if(this.props.logo === undefined || this.props.logo === "urbanFutures") {
            this.setState({
                logo: urbanFutures_white_logo
            })
        }else if(this.props.logo === "transport") {
            this.setState({
                logo: urbanTransport_white_logo
            })
        }else if(this.props.logo === "climate") {
            this.setState({
                logo: urbanClimate_white_logo
            })
        }else if(this.props.logo === "economy") {
            this.setState({
                logo: urbanEconomy_white_logo
            })
        }

        if(this.props.theme === undefined || this.props.theme === "green") {
            this.setState({
                theme: this.GREEN_COLOR
            })
        }else if(this.props.theme === "purple") {
            this.setState({
                theme: this.PURPLE_COLOR
            })
        }else if(this.props.theme === "yellow") {
            this.setState({
                theme: this.YELLOW_COLOR
            })
        }else if(this.props.theme === "red") {
            this.setState({
                theme: this.RED_COLOR
            })
        }
    }

    render() {
        return(
            <Styles>
                <Container fluid className="p-0 m-0 background-color">            
                    <HomeButton />
                    <Jumbo className="jumbo w-100 pl-0 pr-0" style={{backgroundImage:`url(${this.props.backgroundImage})`}}>
                        <div className="header-container">
                            <Col>
                                <Row>
                                    <Col className="d-flex justify-content-center mb-3 p-0">
                                        <img
                                            src={this.state.logo}
                                            alt="Urban Futures Logo"
                                            className="logo"
                                        />
                                    </Col>
                                </Row>
                                <Row className="d-flex align-content-bottom">
                                    <Col xs={{span:10, offset:1}} lg={{span:7}}>
                                        <Row>
                                            <h1>{this.props.titleWhite}</h1>
                                        </Row>
                                        <Row>
                                            <h2 className="title-line" style={{color: this.state.theme, borderColor: this.state.theme}}>{this.props.titleGreen}</h2>
                                        </Row>
                                        <Row>
                                            <p>{this.props.titleDescription}</p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Jumbo>
                </Container>
            </Styles>
            
        );
    }
}

export default TemplateHeader;