import React from 'react';

class DesignCharrettes_Text extends React.Component {    
    TRANSPORT_SUBTITLE = "August 22, 2019";
    TRANSPORT_BODY = "Hyperloop, autonomous vehicles, and high speed trains. How will these technologies change the Quebec City/Windsor corridor?";
    CLIMATE_SUBTITLE = "March 13, 2020";
    CLIMATE_BODY = "Urban Futures will be wrapping up the design workshops with a session on climate change. Stayed tuned for updates.";
    ECONOMY_SUBTITLE = "OCTOBER 31, 2019";
    ECONOMY_BODY = "What will the economic impact be on the Quebec City/Windsor corridor?";

    TEMP_SUBTITLE = "temp subtitle";
    BODY_TEXT = "temp body text";
    
    constructor(props) {
        super(props)

        this.state = {
            display: this.props.charrette
        };

        this.subtitleText = this.TRANSPORT_SUBTITLE;
        this.bodyText = this.TRANSPORT_BODY;
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.charrette !== prevProps.charrette) {
            this.setState({
                display: this.props.charrette
            })
        }

        if(this.state.display !== prevState.display) {
            this.renderText();
        }
    }

    changeDisplay() {
        this.setState({
            display: this.props.charrette
        })
    }
    
    renderText() {
        if(this.state.display === "transport") {
            this.subtitleText = this.TRANSPORT_SUBTITLE;
            this.bodyText = this.TRANSPORT_BODY;
        }else if(this.state.display === "climate") {
            this.subtitleText = this.CLIMATE_SUBTITLE;
            this.bodyText = this.CLIMATE_BODY;
        }else if(this.state.display === "economy") {
            this.subtitleText = this.ECONOMY_SUBTITLE;
            this.bodyText = this.ECONOMY_BODY;
        }else {
            this.subtitleText = this.TEMP_SUBTITLE;
            this.bodyText = this.BODY_TEXT;
        }
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <h3>{this.subtitleText}</h3>
                <p className="m-0">{this.bodyText}</p>
            </div>
        );
    }
}

export default DesignCharrettes_Text;