import React from 'react';
import ResearchersBios from './ResearchersPage_Bios';
import ResearchersData from './ResearchersData';
import ResearchersCards from './ResearchersPage_Cards';

class ResearchersPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            researchers: ResearchersData
        }
    }

    handleCardClick(researcher) {
        this.props.history.push(`/researchers/${researcher.partnerName}`);
    }
    
    removeSpaces(str) {
        return str.replace(" ", "").replace("%20", "");
    }

    render() {
        if(!this.props.match.params.researcher) {
            return <ResearchersCards researchers={this.state.researchers} cardClick={researcher => this.handleCardClick(researcher)} />
        }

        const researcher = this.state.researchers.find(res => {
            return this.removeSpaces(res.partnerName) === this.removeSpaces(this.props.match.params.researcher);
        });

        return <ResearchersBios researcher={researcher} />
    }
}

export default ResearchersPage;