import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .button-container {
        background-color: #262626;
    }

    h4 {
        margin: 0;
    }

    .btn {
        border-radius: 0px;
        border-bottom: 2px solid rgba(0,0,0,0);
        padding: 0px;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .transport-button {
        &:hover {
            border-bottom: 2px solid #8B7295;
        }
    }

    .climate-button {
        &:hover {
            border-bottom: 2px solid #F7EA9E;
        }
    }

    .economy-button {
        &:hover {
            border-bottom: 2px solid #EF907B;
        }
    }
`

class DesignCharrettePage_Buttons extends React.Component {
    TRANSPORT_COLOUR = "#8B7295";
    CLIMATE_COLOUR = "#F7EA9E";
    ECONOMY_COLOUR = "#EF907B";
    INACTIVE_COLOUR = "#595A5A";

    constructor(props) {
        super(props)

        this.state = ({
            activeButton: this.props.page,
            transportColour: this.INACTIVE_COLOUR,
            climateColour: this.INACTIVE_COLOUR,
            economyColour: this.INACTIVE_COLOUR
        });
    }

    componentDidMount() {
        if(this.state.activeButton === "transport") {
            this.setState({
                transportColour: this.TRANSPORT_COLOUR
            });
        } else if(this.state.activeButton === "climate") {
            this.setState({
                climateColour: this.CLIMATE_COLOUR
            });
        } else if(this.state.activeButton === "economy") {
            this.setState({
                economyColour: this.ECONOMY_COLOUR
            });
        }
    }
    
    render() {
        return(
            <Styles>
                <Col xs={{span:12}} className="button-container pt-3 pb-3">
                    <Row>
                        <Col xs={{span:10, offset:1}}>
                            <Row>
                                <Col lg={{span:4}} className="p-0 d-flex justify-content-center">
                                    <Link to="/designcharrette-transport" style={{textDecoration: 'none'}}>
                                        <h4 style={{color: this.state.transportColour}} className="transport-button btn">TRANSPORT</h4>
                                    </Link>
                                </Col>
                                <Col lg={{span:4}} className="p-0 d-flex justify-content-center">
                                    <Link to="/designcharrette-economy" style={{textDecoration: 'none'}}>
                                        <h4 style={{color: this.state.economyColour}} className="economy-button btn">ECONOMY</h4>
                                    </Link>
                                </Col>
                                <Col lg={{span:4}} className="p-0 d-flex justify-content-center">
                                    <Link to="/designcharrette-climate" style={{textDecoration: 'none'}}>
                                        <h4 style={{color: this.state.climateColour}} className="climate-button btn">CLIMATE</h4>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Styles>
        );
    }
}

export default DesignCharrettePage_Buttons;