import React from 'react';
import styled from 'styled-components';
import { Button, Row, Col } from 'react-bootstrap';

const Styles = styled.div`
    .btn {
        background-color: rgba(0,0,0,0);
        border-radius: 0px;
        border-bottom: 2px solid rgba(0,0,0,0);
        padding: 0px;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .transport-button {
        &:hover {
            border-bottom: 2px solid #8B7295;
        }
    }

    .climate-button {
        &:hover {
            border-bottom: 2px solid #F7EA9E;
        }
    }

    .economy-button {
        &:hover {
            border-bottom: 2px solid #EF907B;
        }
    }
`

class DesignCharrettes_Buttons extends React.Component {
    TRANSPORT_COLOUR = "#8B7295";
    CLIMATE_COLOUR = "#F7EA9E";
    ECONOMY_COLOUR = "#EF907B";
    INACTIVE_COLOUR = "#595A5A";

    state = {
        transportColour: this.TRANSPORT_COLOUR,
        climateColour: this.INACTIVE_COLOUR,
        economyColour: this.INACTIVE_COLOUR,
        activeButton: 1
    };

    componentDidUpdate(prevProps) {
        if(this.state.activeButton !== prevProps.activeButton) {
            this.changeColour();
        }
    }

    changeActiveButton(button) {
        this.setState({activeButton: button});
    }

    changeColour() {
        if(this.state.activeButton === 1) {
            this.setState({
                transportColour: this.TRANSPORT_COLOUR,
                climateColour: this.INACTIVE_COLOUR,
                economyColour: this.INACTIVE_COLOUR,
                activeButton: 0
            })
            this.props.handleClick("transport");
        } else if(this.state.activeButton === 2) {
            this.setState({
                transportColour: this.INACTIVE_COLOUR,
                climateColour: this.CLIMATE_COLOUR,
                economyColour: this.INACTIVE_COLOUR,
                activeButton: 0
            })
            this.props.handleClick("climate");
        } else if(this.state.activeButton === 3) {
            this.setState({
                transportColour: this.INACTIVE_COLOUR,
                climateColour: this.INACTIVE_COLOUR,
                economyColour: this.ECONOMY_COLOUR,
                activeButton: 0
            })
            this.props.handleClick("economy");
        }
    }

    render() {
        return(
            <Styles>
                <Col>
                    <Row>
                        <Button variant={`btn mt-3 transport-button`}  size="sm" onClick={() => this.changeActiveButton(1)}>
                            <h4 style={{color: `${this.state.transportColour}`}}>URBAN TRANSPORT</h4>
                        </Button>
                    </Row>
                    <Row>
                        <Button variant={`btn mt-3 economy-button`}  size="sm" onClick={() => this.changeActiveButton(3)}>
                            <h4 style={{color: `${this.state.economyColour}`}}>URBAN ECONOMY</h4>
                        </Button>
                    </Row>
                    <Row>
                        <Button variant={`btn mt-3 climate-button`}  size="sm" onClick={() => this.changeActiveButton(2)}>
                            <h4 style={{color: `${this.state.climateColour}`}}>URBAN CLIMATE</h4>
                        </Button>
                    </Row>
                </Col>
            </Styles>    
        );
    }
}

export default DesignCharrettes_Buttons;