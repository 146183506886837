import React from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron as Jumbo, Row, Col} from 'react-bootstrap';
import smartCity_illustration from '../../assets/homePage/projectOverview/smartCity_illustration.png'
import PrimaryButton from '../PrimaryButton';

export const ProjectOverview = () => (
    <Jumbo fluid className="background-color d-flex align-items-center mb-0"> {/* Use jumbo d-flex align-items-center as className if 100vh jumbo is preferred */}
        <Row className="align-items-center m-0" xs={{offset: 2}}>
            <Col md={{span: 3, offset: 1}} xs={{span:6, offset: 3}}>
                <img
                    src={smartCity_illustration}
                    width="100%"
                    height="auto"
                    alt="Urban Futures Logo"
                />
            </Col>
            <Col md={{span: 6, offset: 1}} xs={{span: 10, offset: 1}}>
                <Row>
                    <h1>PROJECT</h1>
                </Row>
                <Row>
                    <h2 className="title-line mb-3">OVERVIEW</h2>
                </Row>
                <Row>
                    <p className="p1">Collectively, we are concerned with the potentially disruptive, transformative, and destructive impact that the connectivity afforded by data and digital technologies might have on these urban phenomena.  Further, we argue that these phenomena are connected at multiple scales that may result in unanticipated effects upscale or downscale from the point of change. As a research cluster, our approach to the question of smart cities will be unequivocally multidisciplinary and inclusive with the voice of all faculties and stakeholders brought to bear on the full range of research questions.</p>
                </Row>
                <Row>
                    <Link to="/project-overview" style={{textDecoration: 'none'}}>
                        <PrimaryButton buttonText="MORE INFORMATION"/>
                    </Link>
                </Row>
            </Col>
        </Row>
    </Jumbo>
)