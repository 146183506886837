import React from 'react';
import { Carousel, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import CarouselVideos from '../../../assets/homePage/designCharrettes/carousel/carousel_videos.json'
import styled from 'styled-components';

const Styles = styled.div`
    .player-wrapper {
        position: relative;
        width: 100%;
    }
`

class DesignCharrettes_Carousel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            videos: CarouselVideos[this.props.charrette].videos,
            images: this.importImages(require.context("../../../assets/homePage/designCharrettes/carousel", false, /\.(png|jpeg|jpg)$/)).filter(image => image.includes(this.props.charrette)),
            controls: true
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.charrette !== prevProps.charrette) {
            this.setState({
                videos: CarouselVideos[this.props.charrette].videos,
                images: this.importImages(require.context("../../../assets/homePage/designCharrettes/carousel", false, /\.(png|jpeg|jpg)$/)).filter(image => image.includes(this.props.charrette))
            })
        }
    }

    renderVideos() {
        return this.state.videos.map((url, i) => {
            const video = <div className="player-wrapper"><ReactPlayer className="react-player" key={url} url={url} loop volume={0} controls={false} width='100%' height='70vh' /></div>
            return this.renderCol(video, i);
        })
    }

    renderImages() {
        return this.state.images.map((url, i) => {
            const image = <div className="w-100 d-flex justify-content-center"><Image src={url} alt={`Urban ${this.props.charrette}`} style={{maxWidth: "100%", maxHeight: "80vh", height: "auto"}} /></div>
            return this.renderCol(image, i + this.state.images.length);
        })
    }

    importImages = r => {
        return r.keys().map(r);
    };

    renderCol = (item, i) => {
        return (
            <Carousel.Item key={i} className="w-100">
                {item}
            </Carousel.Item>
        );
    }

    render() {
        return(
            <Styles>
                <Carousel controls={true} slide={false} className="w-100"> 
                    {this.renderVideos()}
                    {this.renderImages()}
                </Carousel>
            </Styles>
        );
    }
}

export default DesignCharrettes_Carousel;